/**
 * Features component that queries for data1
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"

const Features = () => {
  const [featuresObj, setFeaturesObj] = useState(null)
  useEffect(() => {
    // get data from GitHub api
    fetch(
      `https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/pages/30`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setFeaturesObj(resultData)
      })
      .catch(e => {
        console.log(e, "err")
      }) // parse JSON from request
  }, [])


  // Client-side Runtime Data Fetching

  return (
    <div>
      {featuresObj?.acf?.features &&(
      <div id="features" className="features-wrap">
        <div className="container">
          <div className="row">
            <div className="features-section">
              <div className="feature-wrapper">
                <div className="heading-wrapper">
                  <div className="heading-common-wrap">
                    <h2>Features/Why Product Labs</h2>
                  </div>
                </div>
                <div className="feature-inner-wrap">
                  {featuresObj &&
                    featuresObj.acf.features.map(feature => (
                      <div
                        className="feature-module"
                        key={feature.feature_icon.title}
                      >
                        <img
                          src={feature.feature_icon.url}
                          alt={feature.feature_icon.title}
                        />
                        <p>{feature.feature_title}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  )
}

export default Features
