import React, {useState, useEffect} from "react"

const FAQs = () => {

    const [faq, setFaq] = useState(null)
  useEffect(() => {
    // get data from GitHub api
    fetch(
      `https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/pages/30`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setFaq(resultData)
    })
      .catch(e => {
        console.log(e, "err")
      }) // parse JSON from request
  }, [])

    const [selectedAccordionindex, setSelectedAccordionIndex] = useState(0)

    const selectAccordion = (index) => {
        setSelectedAccordionIndex(index)
    }
  
    return (
        <div>
        {faq?.acf?.faqs && (
        <div id="faq" className="faq-section-wrapper">
            <div className="container">
                <div className="heading-wrapper">
                    <div className="heading-common-wrap">
                        <h2>FAQ</h2>
                    </div>
                </div><br/>
                <div className="faq-accordian-content">
                    <div className="accordion">
                        {faq && faq.acf.faqs.map((faq,index) => (
                            <div key={faq.faq_id} onClick={() => selectAccordion(index)} className={index === selectedAccordionindex ? 'faq-accordion-wrapper accordion-content open' : 'faq-accordion-wrapper accordion-content'}>
                                <div onClick={() => selectAccordion(index)} className={index === selectedAccordionindex ? 'header accordion-title active' : 'header accordion-title'}>                       
                                    <span className="title">{faq.question}</span>                       
                                </div>
                                <p className="description panel">{faq.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        )}
    </div>
    )
  }
 
export default FAQs