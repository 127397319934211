/**
 * Partners component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

const Partners = () => {
    // Client-side Runtime Data Fetching
   const [partnersObj, setPartnersObj] = useState([])
   useEffect(() => {
       // get data from GitHub api
       fetch(`https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/partners/?per_page=8`)
           .then(response => response.json()) // parse JSON from request
           .then(resultData => {
            setPartnersObj(resultData);
           }) // parse JSON from request

   }, [])

 
   return (
    <div>
        {partnersObj && partnersObj.length>0 &&(


       <div id="partners" className="partner-section">
            <div className="container">
                <div className="heading-wrapper">
                    <div className="heading-common-wrap">
                        <h2>Partners</h2>
                    </div>
                </div>
                <div className="partner-logo-grid">
                    {partnersObj.map((partner,index) => (
                        <div className="logoGrid" key={index}>
                            <div className="logoImage">
                                <a href={partner.acf.partner_website_url} target="_blank">
                                    <img src={partner.acf.partners_image_logo.url} alt="partner-logo"></img>
                                </a>                                                                               
                            </div>
                        </div>
                    ))}
                </div>
                <div className="common-view-more"><Link to="/partners">View More <i className="far fa-angle-right"></i></Link></div>
            </div>                     
       </div>
    )}
    </div>
   )
 }

export default Partners
