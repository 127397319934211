import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
const Testimonial = () => {
  const [testimonialObj, setTestimonialObj] = useState(null)
  useEffect(() => {
    // get data from GitHub api
    fetch(
      `https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/pages/30`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setTestimonialObj(resultData)
    })
      .catch(e => {
        console.log(e, "err")
      }) // parse JSON from request
  }, [])


  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()

  var settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  var settings2 = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    swipeToSlide: true,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          vertical: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          vertical: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          vertical: false,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }

  return (
    <div>
      {testimonialObj?.acf?.testimonials &&(
      <div id="testimonials" className="testimonial-section">
        <div className="container">
          <div className="heading-wrapper">
            <div className="heading-common-wrap">
              <h2>Testimonials</h2>
            </div>
          </div>
          <div className="testimonial-slider">
            <div className="slider1-wrap">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                {...settings1}
              >
                {testimonialObj &&
                  testimonialObj.acf.testimonials.map((testimonial, index) => (
                    <div className="testimonial-data" key={index}>
                      <div className="testimonial-inner-data">
                        <div className="img-wrap">
                          <img
                            src={testimonial.picture.url}
                            alt="testimonial-img1"
                          />
                        </div>
                        <div className="testimonial-content">
                          <h3 className="title">
                            {testimonial.name}
                          </h3>
                          <h5 className="designation">
                            {testimonial.company_name}
                          </h5>
                          <div className="description">
                            <p>{testimonial.testimony_content}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="slider2-wrap">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                {...settings2}
              >
                {testimonialObj &&
                  testimonialObj.acf.testimonials.map((tdata,index) => (
                    <div className="img-wrap" key={index}>
                      <div class="img-wrap-inner">
                        <img src={tdata.picture.url} alt="testimonial-img2" />
                      </div>
                      
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  )
}

export default Testimonial