import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"


const MultipleChecks = () => {

    const [servicesObj, setServicesObj] = useState(null)
    const [selectedindex, setSelectedIndex] = useState(0)
    useEffect(() => {
        // get data from GitHub api
        fetch(
            `https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/services`
        )
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setServicesObj(resultData);
            })
            .catch(e => {
                console.log(e, "err")
            }) // parse JSON from request


        // if()
    }, [])


    const [servicesArray, setServicesArray] = useState([]);
    const handleChange = (e, index) => {
        const value = e.target.value;
        const checked = e.target.checked;
        //console.log(value, checked);
        if (checked) {
            setServicesArray([
                ...servicesArray, value + '_' + index
            ])
        }
        else {
            setServicesArray(servicesArray.filter((e) => (e !== value + '_' + index)));
        }
    }

    const [email, setEmail] = useState("");
    // const [service, setService] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (servicesArray.length === 0) {
            console.log("Array is empty");
            document.getElementById("errormsg").style.display = "block";
        } else {
            document.getElementById("errormsg").style.display = "none";
            //console.log(servicesArray);
            const output = [];
            servicesArray.forEach(myFunction);

            function myFunction(value) {
                value = value.split("_", 1);
                output.push(value);
                //return output;
            }
            //var output = servicesArray.join("\r\n");
            const new_output = output.flatMap(num => num);
            //console.log(new_output);
            const final_output = new_output.join(",");
            console.log(final_output);
            // setService(output);
            if (window.Email) {
                window.Email.send({
                    Host: "smtp.elasticemail.com",
                    Username: "DA5953480B55804CC55FC2C57944A937BD0A8B5EE8501A41BC623B7B5931B2F405E2CBD88D081BA92F16BCD0AE3CB2BA",
                    Password: "DA5953480B55804CC55FC2C57944A937BD0A8B5EE8501A41BC623B7B5931B2F405E2CBD88D081BA92F16BCD0AE3CB2BA",
                    To: email,
                    From: "noreply@productlabs.us",
                    Subject: "Thank you for contacting us",
                    Body: `<!DOCTYPE html
                            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                    <html xmlns="http://www.w3.org/1999/xhtml">

                    <head>
                    <meta name="viewport" content="width=device-width" />
                        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                        <title>Thank You</title>
                    </head>
                    <style>
                        a:hover {
                            text-decoration: underline !important;
                        }
                    </style>

                    <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; color:#4e4e4ede; font-size: 15px;font-weight:500; background-color: #f2f8f9;" leftmargin="0">
                        <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f8f9" style=" font-family: 'Roboto', sans-serif , Arial, Helvetica, sans-serif;">
                            <tr>
                                <td>
                                    <table style="background-color: #f2f8f9; max-width:670px; margin:0 auto;" width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
                                        <tr>
                                            <td style="height:40px;">&nbsp;</td>
                                        </tr>
                                        <!-- Logo -->
                                        <tr>
                                            <td style="text-align:center;">
                                                <a href="https://productlabs.us/" title="Product Labs"><img src="https://productlabs.us/static/logo-54588bf3f7d1e00d9aeea212aecdc9ad.png" title="Logo Image" alt="Product Labs" style="width: 25%;"></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="height:40px;">&nbsp;</td>

                                        </tr>
                                        <!-- Email Content -->
                                        <tr>
                                            <td>
                                                <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0" style="max-width:670px; background:#fff; border-radius:3px; -webkit-box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12);-moz-box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12);box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12); padding:0 40px;line-height: 1.5;border-top: 3px solid #032962;">
                                                    <tr>
                                                        <td style="height:20px;">&nbsp;</td>

                                                    </tr>
                                                    <!-- Title -->
                                                    <tr>
                                                        <!-- <td style="padding:0 15px; text-align:center;">
                                                            <h1 style="color:#3075BA; font-weight:400; margin:0;font-size:32px;">Leave Application</h1>
                                                            <span style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; 
                                                            width:100px;"></span>
                                                        </td> -->
                                                    </tr>
                                                    <!-- Details Table -->
                                                    <tr> 
                                                        <td>
                                                        
                                                            <br/><strong>Dear ${email},</strong><br/>
                                                            <p></p>
                                                            <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                                                <tbody>
                                                                    <tr>
                                                                        <td> 
                                                                            <div>
                                                                                <p style="margin: 5px 0;">Thank you for getting in touch!</p><p style="margin: 5px 0;">We appreciate you contacting us. One of our team members will be getting back to you shortly.<p>While we do our best to answer your queries quickly, it may take about 24 hours to receive a response from us. If your inquiry is urgent, please Email&#160; <a href="mailto:admin@productlabs.us" class="user-name">admin@productlabs.us</a> to talk to one of our team members.</p><p style="margin: 5px 0;">Until then keep browsing &#160;<a title="Product Labs"  href="https://productlabs.us/" target="_blank" >Product Labs</a>
                                                                                </p>
                                                                            </div>  
                                                                            <p style="margin: 0;padding-top: 15px;font-weight: 600;">Regards,<br> Product Labs</p>                                   
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="height:40px;">&nbsp;</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="height:20px;">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:center;">
                                                <p style="font-size:14px; color:#455056bd; line-height:18px; margin:0 0 0;">Copyright © 2023 Product Labs, All rights reserved.</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </body>

                    </html>`
                })//.then(
                 //   message => alert("Sent email successfully")
                //);
            }

            if (window.Email) {
                window.Email.send({
                    Host: "smtp.elasticemail.com",
                    Username: "DA5953480B55804CC55FC2C57944A937BD0A8B5EE8501A41BC623B7B5931B2F405E2CBD88D081BA92F16BCD0AE3CB2BA",
                    Password: "DA5953480B55804CC55FC2C57944A937BD0A8B5EE8501A41BC623B7B5931B2F405E2CBD88D081BA92F16BCD0AE3CB2BA",
                    To: "matt.productlabs@gmail.com",
                    From: "admin@productlabs.us",
                    Subject: "New service request received",
                    Body: `<!DOCTYPE html
                            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                        <html xmlns="http://www.w3.org/1999/xhtml">
                        
                        <head>
                        <meta name="viewport" content="width=device-width" />
                            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                            <title>EduForum</title>
                        </head>
                        <style>
                            a:hover {
                                text-decoration: underline !important;
                            }
                        </style>
                        
                        <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f8f9;" leftmargin="0">
                            <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f8f9" style="@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500); font-family: 'Roboto', sans-serif , Arial, Helvetica, sans-serif;">
                                <tr>
                                    <td>
                                        <table style="background-color: #f2f8f9; max-width:670px; margin:0 auto;" width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
                                            <tr>
                                                <td style="height:80px;">&nbsp;</td>
                                            </tr>
                                            <!-- Logo -->
                                            <tr>
                                                <td style="text-align:center;">
                                                    <a href="https://www.productlabs.us/" title="Product Labs"><img src="https://productlabs.us/static/logo-54588bf3f7d1e00d9aeea212aecdc9ad.png" title="Product Labs" alt="Product Labs"  style="width: 25%;"/></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="height:40px;">&nbsp;</td>
                                            </tr>
                                            <!-- Email Content -->
                                            <tr>
                                                <td>
                                                    <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0" style="max-width:670px; background:#fff; border-radius:3px; -webkit-box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12);-moz-box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12);box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12); padding:0 40px;">
                                                        <tr>
                                                            <td style="height:20px;">&nbsp;</td>
                                                        </tr>
                                                        <!-- Title -->
                                                        <tr>
                                                        </tr>
                                                        <!-- Details Table -->
                                                        <tr>
                                                            <td>
                                                                <br/><strong>Dear Sir,</strong><br/>
                                                                <p>The following details was received from Contact Form:</p>
                                                                <table cellpadding="0" cellspacing="0" style="width: 100%; border: 1px solid #ededed">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:500; color:#171f23de">Email ID:</td>
                                                                            <td style="padding: 10px; border-bottom: 1px solid #ededed; color: #000;font-size: 16px;font-weight: 600;">${email}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        <td style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%;font-weight:500; color:#171f23de">Services Needed:</td>
                                                                        <td style="padding: 10px; border-bottom: 1px solid #ededed; color: #000;font-size: 16px;font-weight: 600; ">${final_output}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="height:40px;">&nbsp;</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="height:20px;">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center;">
                                                    <p style="font-size:14px; color:#455056bd; line-height:18px; margin:0 0 0;">Copyright © 2023 Product Labs. All rights reserved. </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </body>
                        
                        </html>`
                })//.then(
                    // message => alert("Sent email successfully")
                //);
            }


            var xhr = new XMLHttpRequest();
            var url = 'https://api.hsforms.com/submissions/v3/integration/submit/23760119/c1a6c2b2-f071-4d81-ad73-b2ca36af81ba'

            var data = {
            "fields": [
                {
                "name": "Email",
                "value": email
                },
                {
                "name": "Message",
                "value": final_output
                }
            ],
            "legalConsentOptions":{ // Include this object when GDPR options are enabled
              "consent":{
                "consentToProcess":true,
                "text":"I agree to allow Example Company to store and process my personal data.",
                "communications":[
                  {
                    "value":true,
                    "subscriptionTypeId":999,
                    "text":"I agree to receive marketing communications from Example Company."
                  }
                ]
              }
            }
        }   

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function() {
            if(xhr.readyState === 4 && xhr.status === 200) { 
                window.location.href = '/thankYou';
                //alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState === 4 && xhr.status === 400){ 
                //alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
            } else if (xhr.readyState === 4 && xhr.status === 403){ 
                //alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState === 4 && xhr.status === 404){ 
                //alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
           }


        xhr.send(final_data)

        }



            
    }

    const selectTabs = (index) => {
        setSelectedIndex(index)
    }

    return (
        <div>
         {servicesObj && servicesObj.length>0 &&(
        <div id="services" className="form-section-wrapper">
            <Helmet>
                <script
                    src="https://code.jquery.com/jquery-3.6.2.min.js"
                    integrity="sha256-2krYZKh//PcchRtd+H+VyyQoZ/e3EcrkxhM8ycwASPA="
                    crossorigin="anonymous"></script>
                <script src="https://smtpjs.com/v3/smtp.js" />
            </Helmet>

            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="heading-wrapper">
                        <div className="heading-common-wrap">
                            <h2>What Do You <span className="puprple-text">Need Help With?</span></h2>
                        </div>
                    </div> <br /><br />
                    <div className="tabs form-custom-tabs">
                        <ul id="tabs-nav">
                            {servicesObj && (servicesObj.map((services, index) => (
                                <li key={index} onClick={() => selectTabs(index)} className={index === selectedindex ? 'tab-nav-item active' : 'tab-nav-item'}>{services.title.rendered}</li>
                            )))}
                        </ul>
                        <p id="errormsg">Select atleast on option</p>
                        <div id="tabs-content">
                            {servicesObj && servicesObj.map((services, index) => (
                                (index === selectedindex) && (<div key={index} id={services.slug} className="tab-content">
                                    {services?.acf?.description &&(
                                        <div className="service-desc">
                                            <p className="service-desc-content">{services.acf.description}</p>
                                        </div>
                                    )}
                                    

                                    <div className="tab-content-wrap">
                                        {services.acf.form_fields.map((field, index) => (
                                            <div className="fieldwrap" key={index}>
                                                <input id={index} type="checkbox" name="services"
                                                    checked={servicesArray.includes(field.field_name + "_" + index)}
                                                    value={field.field_name} onChange={(e) => handleChange(e, index)} />
                                                <label aria-label="{index}" htmlFor={index}><span></span>{field.field_name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>)
                            ))}
                        </div>
                    </div>
                </div>

                <div className="cta-section">
                    <div className="container">
                        <div className="cta-inner">
                            <div className="form-submit-wrap">
                                <label className="cta-desc">Select options above and click submit</label>
                                <div className="email-submit-wrap">
                                    <input className="email-field" type="email" value={email} placeholder="Email" required onChange={e => setEmail(e.target.value)} />
                                    <input type="submit" value="Submit" className="cta submit" />
                                </div>

                            </div>
                        </div>
                    </div>

                </div>



            </form>
        </div>
         )}

        </div>
    )
}

export default MultipleChecks