import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const People = () => {

  // People Runtime Data Fetching
  const [peopleObj, setPeopleObj] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(
      `https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/people`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setPeopleObj(resultData)
      })
      .catch(e => {
        console.log(e, "err")
      }) // parse JSON from request
  }, [])

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div>
    {peopleObj && peopleObj.length>0 &&(
    <div id="people" className="people-section-wrapper">
      <div className="container">
        <div className="heading-wrapper">
          <div className="heading-common-wrap">
            <h2>People</h2>
          </div>
        </div>
        <div className="peopleListBlock">
          <Slider {...settings}>
            {peopleObj.map(people => (
              <div className="peopleGrid" key={people.id}>
                <div className="peopleImage">
                  <img
                    src={people.acf.people_image.url}
                    alt={people.title.rendered}
                  ></img>
                </div>
                <div className="peopleContent">
                  <h4 className="title">{people.title.rendered}</h4>
                  <div className="description">
                    <p className="designation">{people.acf.designation}</p>
                    <p className="about-people">{people.acf.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
    )}
    </div>
  )
}

export default People