/**
 * Clienteles component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { Link } from "gatsby"


const Clienteles = () => {


   // Client-side Runtime Data Fetching
   const [clientele, setClientele] = useState([])
   useEffect(() => {
       // get data from GitHub api
       fetch(`https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/clienteles/?per_page=8`)
           .then(response => response.json()) // parse JSON from request
           .then(resultData => {
            setClientele(resultData);
           }) // parse JSON from request

   }, [])
 
   return (
    <div>
        {clientele && clientele.length>0 &&(
            <div className="clientele-section">
                <div className="container">
                    <div className="heading-wrapper">
                        <div className="heading-common-wrap">
                            <h2>Clienteles</h2>
                        </div>
                    </div>
                    <div className="client-logo-grid">
                        {clientele.map(client => (
                            <div className="logoGrid" key={client.id}>
                                <div className="logoImage">               
                                    <a href={client.acf.company_url} rel="noreferrer" target="_blank">
                                        <img src={client.acf.client_company_logo_image.url} alt="logo"></img>
                                    </a>             
                                                        
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="common-view-more"><Link to="/clienteles">View More <i className="far fa-angle-right"></i></Link></div>
                </div>                     
            </div>
        )}
       </div>
   )
 }

export default Clienteles
