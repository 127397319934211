/**
 * Clienteles component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useEffect, useState } from 'react';
import { Link } from "gatsby"

const CaseStudy = () => {

    // Client-side Runtime Data Fetching
    const [caseStudies, setCaseStudies] = useState([])
    useEffect(() => {
        // get data from GitHub api
        fetch(`https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/case_study/?per_page=2`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setCaseStudies(resultData);
            }) // parse JSON from request

    }, [])

    return (
        <div>
        {caseStudies && caseStudies.length>0 &&(
        <div id="caseStudy" className="case-studies-section">
            <div className="heading-wrapper">
                <div className="container">
                    <div className="heading-common-wrap">
                        <h2>Case Studies</h2>
                    </div>
                </div>
            </div>
            <div className="case-study-grid-data">
                {caseStudies.map((caseStudy,index) => (
                    <div className="case-studies-module-wrap" key={index}>
                        <div className="container">
                            <div className="case-studies-module">
                                <div className="case-studies-image">
                                    <img src={caseStudy.yoast_head_json.og_image[0].url} alt="case-logo1" />
                                </div>
                                <div className="case-studeies-content">
                                    <img src={caseStudy.acf.company_logo} className="company-logo" alt="case-logo" />
                                    <p className="case-studies-text"><span>Microservice:</span> {caseStudy.acf.micro_service}</p>
                                    <p className="case-studies-text"><span>Impact:</span> {caseStudy.acf.impact}</p>
                                    <p className="case-studies-text"><span>Duration:</span> {caseStudy.acf.duration}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <br />
            <div className="common-view-more">
                <div className="container">
                    <Link to="/caseStudies">View More <i className="far fa-angle-right"></i></Link>
                </div>
            </div>
        </div>
        )}
        </div>
    )
}

export default CaseStudy
