import React, {useState, useEffect} from "react"
import Typewriter from "typewriter-effect";

const HomeBanner = () => {

    const [banner, setBanner] = useState(null)
  useEffect(() => {
    // get data from GitHub api
    fetch(
      `https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/pages/30`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setBanner(resultData)
    })
      .catch(e => {
        console.log(e, "err")
      }) // parse JSON from request
  }, [])

    // console.log(data)
 
   return (
       <div className="home-banner-wrap">          
            <div className="container">
                <div className="banner-main-section">
                    <div className="banner-section">
                        <div className="banner-content-wrap">
                            <h1>{banner?.acf?.hero_title} 
                                <span className="puprple-text">
                                {banner?.acf?.purple_text}</span>
                                {banner?.acf?.typerwriter_text && ( 
                                <Typewriter
                                    options={{
                                        strings: banner?.acf?.typerwriter_text.split(","),
                                        autoStart: true,
                                        loop: true,
                                    }}
                                    />  
                                    )}                               
                            </h1>
                            <p>{banner?.acf?.hero_description}</p>
                        </div>
                        <img className="img-fluid animated" src={banner?.acf?.hero_image.url} alt={banner?.acf?.hero_image.alt}/>
                    </div>
                </div>
            </div>
       </div>
       
   )
 }

export default HomeBanner
